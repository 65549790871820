import React from "react"

import SEO from "../components/seo"


const NotFoundPage = () => (
    <SEO title="404: Not found" />
)

export default NotFoundPage
